.about{
    height: 100vh;
    width: 100%;
    background-image: url('../../media/images/mandalorian-wallpaper.jpeg');
    background-size: cover;
    position: absolute;
    top: 0;
    z-index: -3;
    opacity: 0;
    animation: appear 1s forwards;
    text-align: left;
    display: flex;
    align-items: center;
}

.about-message{
    margin: 20rem;
    height: 50vh;
    overflow: hidden;
    color: gold;
    width: 30rem;
    text-align: center;
    font-size: 2rem;
    & p{
        text-align: justify;
    }
}

.message{
    transform: translateY(35vh);
    animation: roll-up 100s linear forwards;
}


@keyframes roll-up {
    0%{}
    100%{
        transform: translateY(-200vh);
    }
}

@keyframes appear {

    100%{
        opacity: 1;
    }
    
}