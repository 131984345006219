@font-face {
  font-family: 'Mandalorian';
  src: url('./media/fonts/mandalorian/mandalor.ttf');
}

@font-face {
  font-family: 'Milan';
  src: url('./media/fonts/milan/Milan-Regular.otf');
}

@font-face {
  font-family: 'Star Jedi';
  src: url('./media/fonts/star_jedi/starjedi/Starjedi.ttf');
}

@font-face {
  font-family: 'Death Star';
  src: url('./media/fonts/death_star/Death\ Star.otf');
}

@font-face {
  font-family: 'Open Digital';
  src: url('./media/fonts/open_24_display_st/Open\ 24\ Display\ St.ttf');
}

.App {
  text-align: center;
  font-family: 'Death Star';
  text-transform: uppercase;
  letter-spacing: 2px;
}


