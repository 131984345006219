.market{
    height: 100vh;
    width: 100%;
    background-image: url('../../media/images/cantina.jpg');
    background-size: cover;
    position: absolute;
    top: 0;
    z-index: -3;
    opacity: 0;
    animation: appear 1s forwards;
}

.items{
    display: flex;
    flex-direction: row;
    margin: 20vh 2rem 0;
    justify-content: space-around;
    align-items: center;
}

@keyframes appear {

    100%{
        opacity: 1;
    }
    
}

$border-color: rgb(228, 225, 225);

/// Market Item. Go to line 200 for Market Item Form. For Market Form see line 258.

.market-item{
    position: relative;
    .desc{
        opacity: 0;
        transition: all .5s;
    }
    
    &:hover #the-1{
        animation: the-1 8s infinite forwards;
    } 
    &:hover #the-2{
        animation: the-2 8s infinite forwards;
    }
    &:hover #the-3{
        animation: the-3 8s infinite forwards;
    }
    &:hover #the-4{
        animation: the-4 8s infinite forwards;
    }
    &:hover .wrapper{
        background-color: rgba(128,128,128,.5);
    }
    &:hover .desc{
        opacity: 1;
    }
}

.market-item__content{
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 15rem;
    width: 20rem;
    text-shadow: 1px 1px 10px rgba(220, 33, 33, 1);
}

.wrapper{
    background-color: rgba(128,128,128,0.25);
    width: 20rem;
    height: 15rem;
    position: absolute;
    top: 0;
    z-index: -1;
    transition: all .5s;
}

.market-item__background{
    position: absolute;
    top: 0;
    width: 20rem;
    height: 15rem;
    padding: 0;
    margin: 0;
    background-size: cover;
    background-position: center;
    z-index: -2;

}

#the-1{
    background-image: url('../../media/images/helmet-1.JPG');
    transition: all .5s;
}

#the-2{
    background-image: url('../../media/images/crib-1.jpg');
}

#the-3{
    background-image: url('../../media/images/rifle-1.jpg');
}

#the-4{
    background-image: url('../../media/images/ship-1.png');
}

@keyframes the-1 {
    0%{
        background-image: url('../../media/images/helmet-1.JPG');
    }    

    20%{
        background-image: url('../../media/images/helmet-1.JPG');
    }
    25%{
        background-image: url('../../media/images/helmet-2.jpeg');
    }
    70%{
        background-image: url('../../media/images/helmet-2.jpeg');
    }
    75%{
        background-image: url('../../media/images/helmet-1.JPG');
    }   
    100%{
        background-image: url('../../media/images/helmet-1.JPG');
    }   
}

@keyframes the-2 {
    0%{
        background-image: url('../../media/images/crib-1.jpg');
    }    

    20%{
        background-image: url('../../media/images/crib-1.jpg');
    }
    25%{
        background-image: url('../../media/images/crib-2.jpg');
    }
    70%{
        background-image: url('../../media/images/crib-2.jpg');
    }
    75%{
        background-image: url('../../media/images/crib-1.jpg');
    }   
    100%{
        background-image: url('../../media/images/crib-1.jpg');
    }   
}

@keyframes the-3 {
    0%{
        background-image: url('../../media/images/rifle-1.jpg');
    }    

    20%{
        background-image: url('../../media/images/rifle-1.jpg');
    }
    25%{
        background-image: url('../../media/images/rifle-2.jpg');
    }
    70%{
        background-image: url('../../media/images/rifle-2.jpg');
    }
    75%{
        background-image: url('../../media/images/rifle-1.jpg');
    }   
    100%{
        background-image: url('../../media/images/rifle-1.jpg');
    }   
}

@keyframes the-4 {
    0%{
        background-image: url('../../media/images/ship-1.png');
    }    

    20%{
        background-image: url('../../media/images/ship-1.png');
    }
    25%{
        background-image: url('../../media/images/ship-3.jpg');
    }
    70%{
        background-image: url('../../media/images/ship-3.jpg');
    }
    75%{
        background-image: url('../../media/images/ship-1.png');
    }   
    100%{
        background-image: url('../../media/images/ship-1.png');
    }   
}

/// MarketItemForm. For Market Form see line 258.

.market-item-form__container{
    background-color: #3E313B;
    height: 6rem;
    width: 20rem;
    position: relative;
}

.market-item-form{
    position: absolute;
    top: .75rem;
    left: 1.5rem;
    border: solid .25rem $border-color;
    height: 4rem;
    width: 16rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button-reset{
    border: none;
    background-color: gold;
    box-shadow: 1px 1px 10px yellow;
    height: 2rem;
    width: 4rem;
    margin-right: 1rem;
    cursor: pointer;
}

.button{
    border-radius: 100rem;
    height: 2rem;
    width: 2rem;
    border: none;
    cursor: pointer;
}

.button-remove{
    background-color: red;
    box-shadow: 1px 1px 10px red;
    margin-right: .5rem;
}

.button-add{
    background-color: greenyellow;
    box-shadow: 1px 1px 10px greenyellow;
    margin-left: .5rem;
}

.display{
    width: 2rem;
    height: 2rem;
    text-align: center;
    border: none;
    background-color: white;
    box-shadow: 0px 0px 8px 1px white;
    font-family: 'Open Digital';
    color: red;
    text-shadow: 1px 1px 5px red;
}

//// Market Form

.market-form-wrapper{
    margin-top: clamp(2vh, 10vh, 30vh);
    display: flex;
    justify-content: center;
    font-family: 'Open Digital';
}

.market-form-container{
    background-color: #3E313B;
    position: relative;
    height: 10rem;
    width: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.market-form{
    border: solid .25rem $border-color;
    width: 36rem;
    height: 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.form-amount-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.amount-display{
    height: 4rem;
    width: 10rem;
    margin-top: .4rem;
    text-align: center;
    border: none;
    box-shadow: 0px 0px 5px 1px white;
    font-size: 2rem;
    font-family: 'Open Digital';
    color: red;
    text-shadow: 1px 1px 5px red;
    &:disabled{
        background-color: white;
    }
}

.amount-display-label{
    color: red;
    font-size: 1.5rem;
    text-shadow: 1px 1px 5px red;
}

.submit{
    height: 6rem;
    align-self: center;
    width: 8rem;
    border: none;
    background-color: green;
    box-shadow: 1px 1px 10px 2px green;
    font-family: 'Open Digital';
    font-size: 2rem;
    color: red;
    text-shadow: 1px 1px 5px red;
    cursor: pointer;
}